import React from 'react';

import loadable from '@loadable/component';
import Seo from '../components/seo';

import Layout from '../components/layout';

const TeamMembers = loadable(() => import('../components/Team/TeamMebers'), {
  ssr: true,
  fallback: <span className="loading-state">ssr: Loading...</span>,
});

const Team = () => (
  <Layout>
    <Seo title="Team" />
    <TeamMembers prefix="ssr: true" />
  </Layout>
);

export default Team;
